import { Directive, ElementRef, HostListener, OnInit, Input } from "@angular/core";
import { EventEmitter, Output } from '@angular/core';
import { NgControl } from "@angular/forms";

@Directive({
    selector: "[onlyNumbers]"
})

export class DecimalFormatterDirective {
    private el: HTMLInputElement;

    constructor(
        private elementRef: ElementRef,
        private control: NgControl) {
        this.el = elementRef.nativeElement;
    }
    @HostListener('keypress', ['$event']) private onKeyPress(event) {
        const pattern = /[0-9]/;
        let inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }
    @HostListener('blur', ['$event']) private onBlur(event) {
        // set to 1 if no value or less then 1
        let value = this.el.value;
        if (value === "" || parseInt(value, 8) < 1) {
       //    this.control.control.setValue(1);
        }
    }

}
