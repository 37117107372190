import { Component, Inject, Injectable } from "@angular/core";
import { MatDialogModule } from "@angular/material";

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ConfirmDialogComponent } from "../components/confirm-dialog/confirm-dialog.component";

@Injectable()
export class ConfirmDialogService {
    private title: string;
    private text: string;
    constructor(
        private dialog: MatDialog,
    ) { }
    public open(title: string, text: string) {

        let tempData = { title, text };
        return this.dialog.open(ConfirmDialogComponent, {
            width: '40%',
            data: tempData
        });
    }
    public close() {

        this.dialog.closeAll();
    }
}
