import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../shared/services';
import { AlertTypes } from '../../shared/models';

@Component({
    template: '',
    selector: 'logout'
})
export class LogoutComponent implements OnInit {
    constructor(
        private authService: AuthService,
        private router: Router,
        private alertService: AlertService,
        private route: ActivatedRoute) {
    }
    public ngOnInit(): void {
        if (this.route.snapshot.params.error === 'timeout') {
            this.alertService.sendAlert('Your session has expired', AlertTypes.WARNING, false);
        } else {
            this.alertService.sendAlert('You have been logged out', AlertTypes.SUCCESS, true);
        }
        this.authService.logout();
        this.router.navigate(['../login']);
    }
}
