import { Component, Input, forwardRef } from '@angular/core';
import { AppService } from '../../../../app.service';
import { Question } from '../../../models/question';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { WizardQuestionComponent } from '../../../../public/wizard/question/index';

@Component({
  selector: 'app-question-type3',
  styleUrls: ['./question-type3.component.scss'],
  templateUrl: './question-type3.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QuestionType3Component),
      multi: true
    },
  ]
})

export class QuestionType3Component implements ControlValueAccessor {

  private currentValue = -1;
  private currentAnswer = '';
  @Input()
  public _question: Question = new Question();

  constructor(
    private appService: AppService,
    private wizardQuestion: WizardQuestionComponent
  ) {
  }

  public writeValue(value: any): void {
    if (value) {
      this._question = value;
    }
  }
  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  public registerOnTouched(fn: any): void {
    // Empty
  }
  public setDisabledState?(isDisabled: boolean): void {
    // Empty

  }
  public get question() {
    return this._question;
  }

  public set question(val) {
    this._question = val;
    this.propagateChange(val);
  }
  private propagateChange = (_: any) => {    // Empty
  }

  private setCurrentValue(value: number): void {
    this.currentValue = value;
    this.currentAnswer = this.question.values[value];
    this.question.selectedValue = this.currentAnswer;
  }

  private unloadPage(zero = false): void {
    if (zero) {
      this._question.selectedValue = 0;
    }
    this.wizardQuestion.unloadForNextQuestion();
  }
}
