export class Filter {
    public name: string;
    public value: string;
    public type: string;

    constructor(name: string, value: string, type?: string) {
        this.name = name;
        this.value = value;
        if (type) {
            this.type = type;
        } else {
            this.type = 'text';
        }
    }
}
