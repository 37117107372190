import { Component, Input } from '@angular/core';

@Component({
  selector: 'loader',
  styleUrls: ['./loader.component.scss'],
  templateUrl: './loader.component.html',
})
export class LoaderComponent {
   @Input() private _type: "large" | "normal" | "small" = "normal";
  set type(type: "large" | "normal" | "small") {
    this._type = type;
  }
}
