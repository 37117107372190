import { Injectable } from '@angular/core';
import { NgbdAlertComponent } from './alert.component';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs';
import { Alert, AlertTypes } from './../models';
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class CustomTranslateService {

    constructor(
        private translateService: TranslateService
    ) {
        this.translateService = translateService;
    }

    public get(message: string): string {
        let res = this.translateService.instant(message);
        return (res === "") ? message : res;
    }

}
