import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppService } from '../../../app.service';
import { AuthService } from '../../../auth/index';
import { Observable } from 'rxjs';
import { AlertService } from '../../../shared/services/index';
import { AlertTypes, PackageFamily } from '../../../shared/models/index';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-wizard-result',
  styleUrls: ['./wizard-result.component.scss'],
  templateUrl: './wizard-result.component.html'
})
export class WizardResultComponent implements OnInit {

  // AMM3107-1501
  public packageFamily: PackageFamily = {
    family: false,
    choixPersonnePhysique: false,
  };

  private hover = false; // MBR0304-1121
  private selectedOffer: number;
  private recommendedOffer: number;
  private simpleMode = true;
  private mobilityAllRisk = true;
  // private familyPack: boolean = false; // MBR2603-1101
  private buildingRisk = 2;
  private selectedExample = 1;
  private currentCategory: any = {};
  private selectedCategory = 1;
  private examples: any[] = [];
  public scores: any = {};
  public scoresTrad: any = [];
  private products: any = [];
  private selectedProduct: any = [];
  private coverages: any = [];
  private coverageText: any; // MBR2603-1104
  @ViewChild('advice', {static: false})
  private advice: ElementRef;
  @ViewChild('loadedScores', {static: false})
  private loadedScores: ElementRef;
  public showExamples = false;
  private totalPrice: number;
  private loadingDownload: boolean; // MBR2803-1112
  public hasError = false; // MBR2004-1233
  private errorTxt: string; // MBR2004-1233
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private renderer: Renderer2
  ) { }
  public ngOnInit(): void {
    setTimeout(() => {
      this.renderer.listen(this.loadedScores.nativeElement, 'transitionend', () => {
        this.generateScores().subscribe((scoresResult) => {
          this.retrieveProducts();
          this.setupExamples();
        });
      });
      this.renderer.listen(this.loadedScores.nativeElement, 'webkitTransitionEnd', () => {
        this.generateScores().subscribe((scoresResult) => {
          this.retrieveProducts();
          this.setupExamples();
        });
      });
      this.renderer.listen(this.loadedScores.nativeElement, 'animationend', () => {
        this.generateScores().subscribe((scoresResult) => {
          this.retrieveProducts();
          this.setupExamples();
        });
      });
    }, 100);
  }
  public selectOffer(offerNumber: number, top = true): void { // MBR0404-1134 : add top
    // this.selectedProduct = this.products[offerNumber - 1]; // MBR1306-1321
    this.selectedProduct = this.products.filter((product) => product.id === offerNumber); // MBR1306-1321
    this.selectedProduct[0].selected = true; // MBR2206-1332 : ajout [0]
    // console.log(JSON.stringify(this.selectedProduct, null, "   "));
    this.selectedOffer = offerNumber;
    // this.totalPrice = this.products[offerNumber - 1].price; // MBR1306-1321
    this.totalPrice = this.selectedProduct[0].price; // MBR1306-1321
    if (this.packageFamily.family) { // MBR2603-1101
      this.selectFamily(this.packageFamily.family);
    }
    /*
    window.scroll({
      top: 902,
      left: 0,
      behavior: 'smooth'
    });
    */
    if (this.recommendedOffer !== null && this.recommendedOffer !== undefined && this.recommendedOffer !== 0 && top) {
      if (document.querySelector('#products') !== null && (document.querySelector('#products')) !== undefined) {
        (document.querySelector('#products')).scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    }
  }
  public selectFamily(selected: boolean, scoresInfo = false, choixPersonnePhysique = false): void { // MBR2603-1101
    if (!selected) {
        this.packageFamily.choixPersonnePhysique = false;
    } else if (scoresInfo) {
        this.packageFamily.choixPersonnePhysique = choixPersonnePhysique;
    }
    if (this.packageFamily.family !== selected) { // MBR2106-1331
      this.updateFamily(selected).subscribe((res) => {
        if (res['Code'] !== 200) {
          this.appService.handleError(res);
        }
      });
    }

    this.packageFamily.family = selected;
    if (selected) {
      this.totalPrice += 352; // MBR0504-1131
    } else {
      this.totalPrice -= 352; // MBR0504-1131
    }
  }
  public toggleMode(): void {
    this.simpleMode = !this.simpleMode;
  }
  public setCategory(category: number): void {
    this.selectedCategory = category;
    this.selectedExample = 1;
    this.currentCategory = this.examples[category - 1];
  }
  public nextExample(): void {

    if (this.selectedExample === (this.currentCategory.length)) {
      this.selectedExample = 1;
    } else {
      this.selectedExample++;
    }
  }
  public goToAdvice(element) {
    (document.querySelector(element)).scrollIntoView({behavior: 'smooth', block: 'start'});
    /*window.scroll({
      top: 1120,
      left: 0,
      behavior: 'smooth'
    });*/
  }
  public prevExample(): void {
    if (this.selectedExample === 1) {
      this.selectedExample = (this.currentCategory.length);
    } else {
      this.selectedExample--;
    }
  }
  private updateFamily(accepted: boolean): Observable<Object> { // MBR2106-1331
    const simulationID = this.getSimID();
    const params = 'simulationId=' + simulationID + '&familyAccepted=' + accepted +
      '&choixPersonnePhysique' + this.packageFamily.choixPersonnePhysique;
    return this.http.post(environment.apiUrl + '/api/simulations/' + simulationID + '/family/' + accepted + '/' + this.packageFamily.choixPersonnePhysique,
      params,
      this.authService.getHttpOptions()).map((res) => {
        return res;
    }).catch((err) => this.appService.handleError(err));
  }
  private scrollToExamples(element): void {

    this.showExamples = true;
    (document.querySelector(element)).scrollIntoView({behavior: 'smooth', block: 'start'});
    /*window.scroll({
      top: 180,
      left: 0,
      behavior: 'smooth'
    });*/

  }
  private setupExamples(): void {

    const examples: any[] = [];
    const category: any = [];

    let example: any = {};

    // console.log(JSON.stringify(this.scores.examples, null, "    "));

    for (const ex of this.scores.examples) {
      example.image = ex.imageUrl;
      example.situation = ex.situation;
      example.situationFr = ex.situationFr;
      example.situationNl = ex.situationNl;
      example.solution = ex.solution;
      example.solutionFr = ex.solutionFr;
      example.solutionNl = ex.solutionNl;
      examples.push(example);
      example = {};
    }
    // examples.push(category);

    this.examples = examples;
    this.currentCategory = examples;
  }

  private handleError(error: Response | any) {

    this.appService.handleError(error);
    this.loadedScores.nativeElement.classList.add('fadeOut'); // MBR2004-1233
    this.hasError = true; // MBR2004-1233
    this.errorTxt = this.appService.errorText; // MBR2004-1233
    /*
    this.alertService.sendAlert("Error occured:" + error.text(), AlertTypes.DANGER, true);*/
    return Observable.throw(this.errorTxt);

  }

  private DownloadPDF(): void {
    this.loadingDownload = true; // MBR2803-1112
    const simulationID = this.getSimID();

    this.http.get(environment.apiUrl + '/api/simulations/' + simulationID + '/offers', this.authService.getHttpOptions(true, true))
    .map((res: any) => {
      return new Blob([res], {
        type: 'application/pdf'
      })
    })
    .catch((err) => this.appService.handleError(err)) // MBR0901-1401
    .subscribe((blobContent) => {
      // let urlCreator = window.URL;
      // let url = urlCreator.createObjectURL(blobContent);
      // this.renderer.setElementProperty(this.preview.nativeElement, 'src', url);
      // window.open(url);
      const d = new Date();
      saveAs(blobContent, 'DAS' + d.getDate() + '' + d.getMonth() + 1 + '' + d.getFullYear() + '.pdf');
      this.loadingDownload = false; // MBR2803-1112
   });
  }

  private generateScores(): Observable<Object> {
    let simulationID = this.getSimID();
    if (simulationID === '') {
      simulationID = localStorage.getItem('simID');
    } else {
      localStorage.setItem('simID', simulationID);
    }

    return this.http.get(environment.apiUrl + '/api/simulations/' + simulationID + '/scores', this.authService.getHttpOptions()).map((scores) => {
      this.scores = scores;
      this.scoresTrad = this.scores;
      return scores;
    }).catch((err) => this.handleError(err));
  }

  private retrieveProducts(): void {

    if (this.appService.isDas) {
      this.selectFamily(this.scores.family, true, this.scores.choixPersonnePhysique); // MBR2603-1101
    }

    this.products = this.scores.products;

    for (const product of this.products) {
      this.coverages.push(product.coverages);
      if (product.advised) {
        // console.log("ok");
        // this.selectedOffer = product.id;
        this.totalPrice = product.price;
        // this.selectedProduct = product;
        // this.selectedProduct.selected = true;
        this.selectOffer(product.id);
        this.recommendedOffer = product.id;
        // console.log(this.selectedProduct, null, "   ");
        this.coverageText = product.coverages; // MBR2603-1104
      }
    }
    // console.log("coverages = " + JSON.stringify(this.coverages, null, "   "));
  }

  private updateProduct(): Observable<Object> {
    const simulationID = this.getSimID();
    const productPOST: any = { // MBR2603-1101
      products : [this.selectedProduct[0]], // MBR2206-1332 : ajout [0]
      family : this.packageFamily.family,
      choixPersonnePhysique: this.packageFamily.choixPersonnePhysique
    };
    return this.http.post(environment.apiUrl + '/api/simulations/' + simulationID + '/products',
      JSON.stringify(productPOST),
      this.authService.getHttpOptions(true))
      .catch((err) => this.handleError(err)); // MBR2004-1233 handleError
    // window.location.href = 'https://extranet2.das.be/Extranet?action=PMETOPOL';
  }

  private getExtranetToken(): Observable<Object> {
    const simulationID = this.getSimID();
    return this.http.get(environment.apiUrl + '/api/tokens/' + simulationID + '/extranet-token', this.authService.getHttpOptions(true))
      .catch((err) => this.handleError(err)); // MBR2004-1233 : handleError
  }

  private createContract(): void {
    this.updateProduct().subscribe((res) => {
      this.getExtranetToken().subscribe((extranetToken) => {
        window.location.href = environment.createContractUrl;
      });
    });
  }

  private getSimID(): string {
    let simulationID = this.appService.simulationNumber;
    if (simulationID === '') {
      simulationID = localStorage.getItem('simID');
    } else {
      localStorage.setItem('simID', simulationID);
    }

    return simulationID;
  }

  private getClass(prod: any[]): string { // MBR1306-1321 : prod: any[]
    const index = this.products.indexOf(prod); // MBR1306-1321
    let className;

    switch (index) { // MBR1306-1321
      case 0: // MBR1306-1321
        className = 'icon-basis';
        break;
      case 1: // MBR1306-1321
        className = 'icon-plus';
        break;
      case 2: // MBR1306-1321
        className = 'icon-extra';
        break;
      default:
        className = '';
        break;
    }

    return className;
  }

  public getLang(): string {
    const lang = this.appService.getLanguage();
    return lang.charAt(0).toUpperCase() + lang.slice(1).toLowerCase();
  }

  private reload(): void { // MBR2004-1233
    window.location.reload();
  }
}
