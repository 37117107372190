import { Component, OnInit, ViewEncapsulation, AfterViewInit, Renderer2, HostListener } from '@angular/core';
import { AppService } from './app.service';
import { AuthService } from './auth';
import { WizardService } from './shared/services/wizard.service';
import { CustomTranslateService } from './shared/services/index';
import { Router, NavigationEnd } from '@angular/router';
import { Intercom } from 'ng-intercom';
import { PlatformLocation } from '@angular/common'; // MBR2004-1231

@Component({
    selector: 'app-app',
    encapsulation: ViewEncapsulation.None,
    styleUrls: [
        './app.component.scss'
    ],

    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

    public name = this.translation.get('DAS | Bereken uw Legal Risk Calculator ®');
    private currentUnixTime = Date.now() / 1000 || 0; // MBR0901-1401
    constructor(
        public appService: AppService, // MBR0901-1401
        private renderer: Renderer2,
        private authService: AuthService,
        private translation: CustomTranslateService,
        private wizardService: WizardService,
        private router: Router,
        private location: PlatformLocation, // MBR2004-1231
        public intercom: Intercom) {

            location.onPopState(() => { // MBR2004-1231 : if back button -> go to homepage
                this.authService.returnToExtra(); // this.router.navigate(['/risk-calculator']);
                // => remplacé par returnToExtra car bug lorsqu'on est sur la page de résultat + back + commencer le questionnaire
            });
    }

    public ngOnInit(): void {
        // console.log(this.router.url);
        // console.log(this._cookieService.get('RiskCalculator'));
        this.appService.extractLanguage();
        /* MBR0901-1401
        if (this._cookieService.get('RiskCalculator') === undefined) {
            /* TEST
            this.appService.catchError = true;
            this.appService.errorText = JSON
            .parse('{"Code":400,"Message":"You are not authorized to retrieve a secure token with unsecure token"}');
            this.animated();
            this.router.navigate(['/risk-calculator']);
            this.intercom.boot({
                app_id: "mer964ge",
                created_at: currentUnixTime
              });
            /* TEST
            // window.location.href = 'https://extranet.das.be/Extranet22';
        } else {
        */
        this.router.events.filter((event: any) => event instanceof NavigationEnd).subscribe(event => {
            if (event.url.split('?')[0] === '/redirect') {
                this.appService.verifyTokenOnRedirect = true;
                this.animated();
            } else {
                if (this.appService.verifyTokenOnRedirect === false) { // sinon il exécute secure-token à chaque event de navigation
                    this.authService.verifyToken().subscribe((secureTokenAPI) => {
                        // console.log(this.brokerName);
                        // this.appService.extractLanguage();
    
                        switch (this.appService.action) {
                            case 'RESUMEPMECALC' :
                                this.authService.resumePMECalc(this.appService.actionValue).subscribe((resume) => {
                                    // console.log(this.appService.trackQuestionId);
                                    // if (this.appService.trackQuestionId <= 2) { // MBR2304-1241 // MBR2006-1331
                                    this.router.navigate(['./company-information']);
                                    // } else { // MBR2006-1331
                                        // this.router.navigate(['./wizard/start']); // MBR2006-1331
                                    // } // MBR2006-1331
                                    /*
                                    switch (this.appService.trackQuestionId) {
                                        case 1:
                                            this.router.navigate(['./company-information']);
    
                                        case 2:
                                            this.router.navigate(['./company-information/additional']);
    
                                        default:
                                        this.router.navigate(['./wizard/start']);
                                    }
                                    */
                                    this.animated();
                                });
                                break;
                            case 'RESULTPMECALC' :
                                this.appService.simulationNumber = this.appService.actionValue;
                                this.router.navigate(['./wizard/result']);
                                this.animated();
                                break;
                            case 'NEWPMECALC' :
                                this.animated();
                                break;
                            default: // MBR0901-1401
                                /* TEST sur IE, secure-token retourne 200 même sans cookie mais le résultat est = à null */
                                 this.animatedTest();
                                /* TEST */
                                // window.location.href = 'https://extranet.das.be/Extranet23';
                        }
                    },
                    (error) => {
                        /* TEST */
                        this.animatedTest(); // MBR0901-1401
                        /* TEST */
                        // window.location.href = 'https://extranet.das.be/Extranet23';
                    });
                }
            }
        });
        // }
    }

    @HostListener('window:beforeunload', ['$event'])
        public beforeunloadHandler($event: any) {
        // $event.returnValue = "Are you sure?";
        this.intercom.shutdown();
    }

    private animatedTest(): void { // MBR0901-1401
        const element = (<HTMLElement> document.getElementById('loadingScreen'));
        if (element === null || element.parentNode === null) {
            return;
        }
        this.renderer.listen(element, 'transitionend', () => {
            this.removeLoadingScreen();
        });
        this.renderer.listen(element, 'webkitTransitionEnd', () => {
            this.removeLoadingScreen();
        });
        this.renderer.listen(element, 'animationend', () => {
            this.removeLoadingScreen();
        });
        //  this.wizardService.generateSampleQuestions();
        setTimeout(function () { element.classList.remove('loading'); }, 500);
        setTimeout(function () {
            if (element !== null && element.parentNode !== null) {
                element.parentNode.removeChild(element);
            }
        }, 1500);
        // let currentUnixTime = Date.now() / 1000 | 0;
        if (this.appService.isDas) {
            this.intercom.boot({
                app_id: 'mer964ge',
                created_at: this.currentUnixTime,
                // Supports all optional configuration.
                widget: {
                    activator: '#intercom'
                }
            });
        }
    }

    private animated(): void {
        const element = (<HTMLElement> document.getElementById('loadingScreen'));
        if (element === null || element.parentNode === null) {
            return;
        }
        this.renderer.listen(element, 'transitionend', () => {
            this.removeLoadingScreen();
        });
        this.renderer.listen(element, 'webkitTransitionEnd', () => {
            this.removeLoadingScreen();
        });
        this.renderer.listen(element, 'animationend', () => {
            this.removeLoadingScreen();
        });
        //  this.wizardService.generateSampleQuestions();
        setTimeout(function () { element.classList.remove('loading'); }, 500);
        setTimeout(function () {
            if (element !== null && element.parentNode !== null) {
                element.parentNode.removeChild(element);
            }
        }, 1500);
        // MBR0901-1401
        // ------- INTERCOM ----------
        // l'identification de l'utilisateur se fait sur base de l'adresse email
        // let currentUnixTime = Date.now() / 1000 | 0;
        if (this.appService.isDas) {
            this.intercom.boot({
                app_id: 'mer964ge',
                // ExtranetID: this.appService.brokerName,
                // name: "Test Tester",
                user_id: this.appService.brokerName,
                email: this.appService.brokerEmail,
                created_at: this.currentUnixTime,
                // Supports all optional configuration.
                widget: {
                    activator: '#intercom'
                }
            });
        }
    }
    private removeLoadingScreen(): void {
        const element = (<HTMLElement> document.getElementById('loadingScreen'));
        if (element !== null && element.parentNode !== null) {
            element.parentNode.removeChild(element);
        }
    }
}
