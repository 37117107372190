import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs/Observable";
import { Map } from "rxjs/Map";
import { AppService } from '../../app.service';

@Pipe({ name: 'doTranslate' })
export class TranslatePipe implements PipeTransform {
    constructor(private translate: TranslateService, private sanitizer: DomSanitizer, private appService: AppService) {
        this.translate = translate;
        this.sanitizer = sanitizer;
    }

    public transform(html) {

        let language = this.appService.getLanguage();
        this.translate.use(language);
        // let translation = this.translate.get(html);

        return this.translate.stream(html)
            .map((res) => {
                let returnValue = res;
                if (res === "") {
                    returnValue = html;
                }
                return returnValue;
            });
    }
}
