import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Injector,
  Renderer2,
  AfterViewInit
} from '@angular/core';

import { AppService } from '../../app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'rechtsbijstand',
  styleUrls: ['./rechtsbijstand.component.scss'],
  templateUrl: './rechtsbijstand.component.html'
})
export class RechtsbijstandComponent  {
  private companyNumber: String = "";
  @ViewChild("checkList",{static: false})
  private checkList: ElementRef;
  @ViewChild("inputContainer",{static: false})
  private inputContainer: ElementRef;
  @ViewChild("disclaimer",{static: false})
  private disclaimer: ElementRef;

  constructor(
    private appService: AppService,
    private router: Router,
    private renderer: Renderer2
  ) {
  }

  private animateLoading(): void {
    this.checkList.nativeElement.classList.add('fadeOutUp');
    this.inputContainer.nativeElement.classList.add('fadeOut');
    this.disclaimer.nativeElement.classList.add('fadeOut');

  }
  public gotoStart(): void {
    this.router.navigate(['../risk-calculator']);

  }

}
