import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Injector,
  Renderer2,
  AfterViewInit
} from '@angular/core';

import { AppService } from '../../app.service';
import { Router } from '@angular/router';

@Component({
  selector: 'legal-problem',
  styleUrls: ['./legal-problem.component.scss'],
  templateUrl: './legal-problem.component.html'
})
export class LegalProblemComponent {

  constructor(
    private appService: AppService,
    private router: Router,
    private renderer: Renderer2
  ) {
  }
  public gotoStart(): void {
    this.router.navigate(['../legal-assistance']);

  }

}
