import { Component } from '@angular/core';

@Component({
  selector: 'no-content',
  styleUrls: ['./no-content.component.scss'],
  templateUrl: './no-content.component.html'
})
export class NoContentComponent {

}
