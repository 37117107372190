import { Component, Input, forwardRef, ViewChild, AfterViewInit, ElementRef, Renderer2, HostListener, OnInit } from '@angular/core';
import { AppService } from '../../../../app.service';
import { Question } from '../../../models/question';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../../auth/index';
import { CustomTranslateService, AlertService } from '../../../services/index';
import { Observable } from 'rxjs';
import { AlertTypes } from '../../../models/index';
import { WizardQuestionComponent } from '../../../../public/wizard/question/index';
import { QuestionType5FormAddressComponent } from './type-5-form-address/question-type5-form-address.component'; // CSA1309 - 1362
import { filter, debounceTime, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-question-type5',
    styleUrls: ['./question-type5.component.scss'],
    templateUrl: './question-type5.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuestionType5Component),
            multi: true
        },
    ]
})

export class QuestionType5Component implements ControlValueAccessor, AfterViewInit, OnInit {
    @ViewChild('loadedHeader', {static: false})
    private loadedHeader: ElementRef;
    @ViewChild('toevoegen', {static: false})
    private toevoegen: ElementRef;
    @ViewChild('addBuildingForm', {static: false})
    private addBuildingForm: ElementRef;
    @ViewChild('loadingInformation', {static: false})
    private loadingInformation: ElementRef;
    @ViewChild('street', {static: false}) // MBR1409-1351
    private street: ElementRef; // MBR1409-1351
    @ViewChild('buildNumb', {static: false}) // MBR1409-1351
    private buildNumb: ElementRef; // MBR1409-1351
    @ViewChild('buildBoxNumb', {static: false})
    private buildBoxNumb: ElementRef;
    @ViewChild('zip', {static: false}) // MBR1409-1351
    private zip: ElementRef; // MBR1409-1351
    @ViewChild('city', {static: false})// MBR1409-1351
    private city: ElementRef; // MBR1409-1351
    @ViewChild('btn', {static: false}) // MBR1409-1351
    private btn: ElementRef; // MBR1409-1351

    public buildings: any = [];
    // private addCompanyResult: any = {};
    // private companyNumber: string = "";
    private subcat = 41;
    private dataLoaded = false;
    private listener1: any;
    private listener2: any;
    private listener3: any;
    // private didLoadAlready: boolean = false;
    private resultCode: number;
    private resultMessage: string;

    /* MBR1311-1351
    private buildingCity: string = '';
    private buildingNumber: string = '';
    private buildingZipcode: string = '';
    private buildingStreet: string = '';

    private _buildingCity: FormControl;
    private _buildingNumber: FormControl;
    private _buildingZipcode: FormControl;
    private _buildingStreet: FormControl;
    */
    private currentValue = -1;
    private currentAnswers: Array<{
        owned: boolean,
        number: string,
        box: string,
        street: string,
        zipCode: string,
        city: string,
        country: string,
        isBusinessOffice: boolean,
        isRegisteredOffice: boolean,
        isHouse: boolean,
        id: number,
        mainEnterpriseId: number
    }> = [];

    private checked: Array<{ id: number, type: string }> = [];
    @Input()
    private _question: Question;

    public showFields = false; // MBR1409-1351
    private focusFields: any = { // MBR1409-1351
        address: false,
        num: false,
        boxnum: false,
        cp: false,
        city: false
    };
    private notAdded = false; // MBR1409-1351

    public addressForm = new FormGroup({ // MBR1311-1351
        _buildingCity: new FormControl('', Validators.required),
        _buildingNumber: new FormControl('', Validators.required),
        _buildingBoxNumber: new FormControl(''),
        _buildingZipcode: new FormControl('', [
            Validators.required,
            Validators.pattern(/^\d+$/)
        ]),
        _buildingStreet: new FormControl('', Validators.required)
     });

    private options: string[] = []; // MBR1311-1351
    private streets: string[] = []; // MBR1311-1351
    private filteredOptions: string[] = []; // MBR1311-1351
    private accessToken: string; // MBR1311-1351
    private error: string | null; // MBR1311-1351
    private term$: Observable<string>; // MBR1311-1351

    constructor(
        public appService: AppService,
        private renderer: Renderer2,
        private router: Router,
        private authService: AuthService,
        private translation: CustomTranslateService,
        private http: HttpClient,
        private alertService: AlertService,
        private wizardQuestion: WizardQuestionComponent
    ) {
    }

    public ngOnInit(): void { // MBR1311-1351

    }

    public ngAfterViewInit(): void {
        const self = this;
        const simID = this.appService.simulationNumber;

        if (simID === null || simID === undefined || simID === '') {
            this.router.navigate(['/risk-calculator']);
        } else {
            this.getBuildings(simID);
        }
    }

    public writeValue(value: any): void {
        if (value) {
            this._question = value;
            this.setInitial();
        }
    }
    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    public registerOnTouched(fn: any): void {
        // Empty
    }
    public setDisabledState?(isDisabled: boolean): void {
        // Empty
    }
    public get question() {
        return this._question;
    }
    public set question(val) {
        this._question = val;
        this.propagateChange(val);
    }

    public setAddress(data): void { // CSA1309 - 1362

        if ('ret' in data) {
            this.rmvDisplayBuildingForm();
            if (data['ret']) {
                /* MBR1311-1351
                this.buildingNumber = data['buildingNumber'];
                this.buildingStreet = data['buildingStreet'];
                this.buildingZipcode = data['buildingZipcode'];
                this.buildingCity = data['buildingCity'];
                */
                this.addressForm.get(['_buildingNumber']).setValue(data['buildingNumber']); // MBR1311-1351
                this.addressForm.get(['_buildingBoxNumber']).setValue(data['buildingBoxNumber']);
                this.addressForm.get(['_buildingStreet']).setValue(data['buildingStreet']); // MBR1311-1351
                this.addressForm.get(['_buildingZipcode']).setValue(data['buildingZipcode']); // MBR1311-1351
                this.addressForm.get(['_buildingCity']).setValue(data['buildingCity']); // MBR1311-1351

                this.addBuilding();
            }
        }
    }

    public displayBuildingForm(): void { // CSA1309 - 1362
        const self = this;
        setTimeout(function () {
            self.toevoegen.nativeElement.classList.add('hide');
            self.addBuildingForm.nativeElement.classList.remove('hide');
            self.addBuildingForm.nativeElement.classList.add('fadeInUp');
            self.renderListener();
        }, 100);
    }

    public showAddressFieldsIfNotDAS() {
        if (this.appService.isDas === true) {
            return true;
        } else {
            if (this.buildings.length < 2) {
                return true;
            } else {
                return false;
            }
        }
    }

    public displayFields(): void { // MBR1409-1351
        const self = this;
        self.showFields = !self.showFields;
        this.wizardQuestion.questionType5AddressFieldsDisplayed = self.showFields; // MBR2812-136
        if (!self.showFields) {
            this.appService.catchError = false; // MBR1401-1401
            this.appService.errorText = '';  // MBR1401-1401

            self.addressForm.get(['_buildingNumber']).setValue(''); // MBR1311-1351
            self.addressForm.get(['_buildingBoxNumber']).setValue('');
            self.addressForm.get(['_buildingStreet']).setValue(''); // MBR1311-1351
            self.addressForm.get(['_buildingZipcode']).setValue(''); // MBR1311-1351
            self.addressForm.get(['_buildingCity']).setValue(''); // MBR1311-1351
            self.notAdded = false;

            if (this.showAddressFieldsIfNotDAS()) {
                self.street.nativeElement.classList.remove('fadeInUp');
                self.buildNumb.nativeElement.classList.remove('fadeInUp');
                self.buildBoxNumb.nativeElement.classList.remove('fadeInUp');
                self.zip.nativeElement.classList.remove('fadeInUp');
                self.city.nativeElement.classList.remove('fadeInUp');
                self.btn.nativeElement.classList.remove('fadeInUp');
            }
        } else {
            if (this.showAddressFieldsIfNotDAS()) {
                self.street.nativeElement.classList.add('fadeInUp');
                self.buildNumb.nativeElement.classList.add('fadeInUp');
                self.buildBoxNumb.nativeElement.classList.add('fadeInUp');
                self.zip.nativeElement.classList.add('fadeInUp');
                self.city.nativeElement.classList.add('fadeInUp');
                self.btn.nativeElement.classList.add('fadeInUp');
            }
        }
    }

    private rmvDisplayBuildingForm(): void { // CSA1309 - 1362
        const self = this;
        setTimeout(function () {
            self.toevoegen.nativeElement.classList.remove('hide');
            self.addBuildingForm.nativeElement.classList.remove('fadeInUp');
            self.addBuildingForm.nativeElement.classList.add('hide');
            self.renderListener();
        }, 300);
    }

    private renderListener() {
        this.listener1 = this.renderer.listen(this.addBuildingForm.nativeElement, 'transitionend', () => {
            this.removeBindingsLoad();
        });
        this.listener2 = this.renderer.listen(this.addBuildingForm.nativeElement, 'webkitTransitionEnd', () => {
            this.removeBindingsLoad();
        });
        this.listener3 = this.renderer.listen(this.addBuildingForm.nativeElement, 'animationend', () => {
            this.removeBindingsLoad();
        });
    }

    private propagateChange = (_: any) => {    // Empty
    }
    private setInitial(): void {
        if (this._question.selectedValue) {
            this.currentAnswers = this._question.selectedValue;

        }
    }

    private removeBindingsLoad(): void {
        this.listener1();
        this.listener2();
        this.listener3();
    }
    private deleteBuilding(buildingId): void {

        const simulationID = this.appService.simulationNumber;
        const index = this.buildings.map(function (d) { return d['id']; }).indexOf(buildingId);
        // let vat = this.companyNumber;
        this.buildings.splice(index, 1);
        // console.log(buildingId);
        this.http.delete(environment.apiUrl + '/api/simulations/' + simulationID + '/buildings/' + buildingId,
            this.authService.getHttpOptions()).catch((err) => this.appService.handleError(err)).subscribe((e) => {
            // console.log(e);
        });
    }
    private addBindingsLoad(): void {
        const self = this;
        setTimeout(function () {
            self.loadingInformation.nativeElement.classList.add('fadeOutUp');
            // self.didLoadAlready = true;
            self.addBuildingForm.nativeElement.classList.remove('fadeOutUp');
            self.addBuildingForm.nativeElement.classList.add('fadeInUp');

            self.renderListener();
        }, 2000);
    }

    private doAddBuilding(): void {
        const simulationID = this.appService.simulationNumber;
        const addBuilding: any = [];
        const isBusinessOfficeTrue = !this.appService.isDas ? true : false;
        const params: any = {
            owned: false,
            number: this.addressForm.controls['_buildingNumber'].value, // MBR1311-1351
            box: this.addressForm.controls['_buildingBoxNumber'].value,
            street: this.addressForm.controls['_buildingStreet'].value, // MBR1311-1351
            zipCode: this.addressForm.controls['_buildingZipcode'].value, // MBR1311-1351
            city: this.addressForm.controls['_buildingCity'].value, // MBR1311-1351
            country: '',
            isBusinessOffice: isBusinessOfficeTrue,
            isRegisteredOffice: false,
            isHouse: false,
            mainEnterpriseId: simulationID
        };

        this.buildings.push(params);
        this.appService.catchError = false; // MBR1401-1401
        this.appService.errorText = ''; // MBR1401-1401
        this.http.post(environment.apiUrl + '/api/simulations/' + simulationID + '/buildings',
            JSON.stringify(this.buildings), this.authService.getHttpOptions(true))
            .catch((err) => this.appService.handleError(err)).subscribe((e) => {
                this.getBuildings(simulationID);
                this.showFields = !this.showFields; // MBR1401-1401
                this.displayFields(); // MBR1401-1401
        },
        (error) => { // MBR1401-1401
            this.buildings.splice(-1, 1);
        });

        // this.buildings.push("");
        /*
        this.buildingNumber = '';
        this.buildingStreet = '';
        this.buildingZipcode = '';
        this.buildingCity = '';
        */
       // this.displayFields(); // MBR1401-1401
    }
    private getBuildings(simulationID: string): void {
        this.http.get(environment.apiUrl + '/api/simulations/' + simulationID + '/buildings', this.authService.getHttpOptions())
            .catch((err) => this.appService.handleError(err)) // MBR0901-1401 this.buildingError(err))
            .subscribe((e) => {
            // console.log('buildings : ' + JSON.stringify(e, null, "   "));
            this.buildings = e;
            this._question.selectedValue = this.buildings;
        });
    }
    public addBuilding(): void {
        this.doAddBuilding();
        this.addBindingsLoad();
        this.showFields = false; // MBR1409-1351
        this.wizardQuestion.questionType5AddressFieldsDisplayed = false; // MBR2812-136
        this.notAdded = false; // MBR1409-1351
        this.loadingInformation.nativeElement.classList.add('fadeInUp');
        this.loadingInformation.nativeElement.classList.remove('fadeOutUp');
        this.addBuildingForm.nativeElement.classList.add('fadeOutUp');
    }
    private checkboxChecked(id: number, type: string): boolean {
        // console.log("isChecked ? " + JSON.stringify(this.buildings.filter((o) => { return o.id === id; }), null, "   "));
        switch (type) {
            case 'isHouse': return (this.buildings.filter((o) => { return o.id === id; })[0].isHouse);
            case 'owned': return (this.buildings.filter((o) => { return o.id === id; })[0].owned);
            case 'isBusinessOffice': return (this.buildings.filter((o) => { return o.id === id; })[0].isBusinessOffice); // MBR2203-1101
            default: return false;
        }
        // return (this.checked.filter((o) => { return o.id === id && o.type === type; }).length > 0);
    }
    toggleCheckbox(refId: number, refType: string): void {
        const currentAnswers = this.buildings; // on charge la liste des buildings
        const currentIndex = currentAnswers.map(function (d) { return d['id']; }).indexOf(refId);
        // on récupère l'index du building sélectionné

        if (currentIndex < 0) {
            this.alertService.sendAlert(this.translation
                .get('Er was een probleem bij het ophalen van de bedrijfsinformatie'), AlertTypes.WARNING, false);
        } else {
            switch (refType) {
                case 'isHouse':
                    if (this.checkboxChecked(refId, refType)) {
                        // this.checked = this.checked.filter((o) => { return o.id !== refId && o.type !== refType; });
                        currentAnswers[currentIndex].isHouse = false;
                    } else {
                        // this.checked.push({id: refId, type: refType});
                        currentAnswers[currentIndex].isHouse = true;
                    }
                    break;
                case 'owned':
                    if (this.checkboxChecked(refId, refType)) {
                        // this.checked = this.checked.filter((o) => { return o.id !== refId && o.type !== refType; });
                        currentAnswers[currentIndex].owned = false;
                    } else {
                        // this.checked.push({id: refId, type: refType});
                        currentAnswers[currentIndex].owned = true;
                    }
                    break;
                case 'isBusinessOffice': // MBR2203-1101
                    if (this.checkboxChecked(refId, refType)) {
                        // this.checked = this.checked.filter((o) => { return o.id !== refId && o.type !== refType; });
                        currentAnswers[currentIndex].isBusinessOffice = false;
                    } else {
                        // this.checked.push({id: refId, type: refType});
                        currentAnswers[currentIndex].isBusinessOffice = true;
                    }
                    break;
            }
        }
        this.buildings = currentAnswers;
        this._question.selectedValue = currentAnswers;
    }

    postBuildings(): void {
        const simulationID = this.appService.simulationNumber;

        this.http.post(environment.apiUrl + '/api/simulations/' + simulationID + '/buildings', this.buildings,
            this.authService.getHttpOptions(true))
            .catch((err) => this.appService.handleError(err)).subscribe((e) => {
                // console.log(e);
            });
    }

    public unloadPage(): void {
        this.appService.catchError = false; // MBR1401-1401
        this.appService.errorText = ''; // MBR1401-1401
        if (this.showFields) { // MBR1409-1351
            this.notAdded = true;
        } else {
            this.wizardQuestion.unloadForNextQuestion();
        }
    }

    getAddress(place: object) {
        const address = {street_number: '', route: '', locality: '', country: '', postal_code: ''};
        place['address_components'].forEach(comp => {
            address[comp.types[0]] = comp['long_name'];
        });
        this.addressForm.controls._buildingStreet.setValue(address.route);
        this.addressForm.controls._buildingCity.setValue(address.locality);
        this.addressForm.controls._buildingZipcode.setValue(address.postal_code);
        this.addressForm.controls._buildingNumber.setValue(address.street_number);
    }
}
