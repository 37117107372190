import { NgModule, ApplicationRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { SharedModule } from './shared';
import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppService, InternalStateType } from './app.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxClickToEditModule } from 'ngx-click-to-edit';
import '../styles/styles.scss';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './shared/services/confirm-dialog.service';
import { NgbdAlertComponent } from './shared/components/alert/index';
import { TranslatePoHttpLoader } from '@biesbjerg/ngx-translate-po-http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { CustomTranslateService } from './shared/services/custom-translate.service';
import { PageHeaderComponent } from './shared/components/page-header/index';
import { PageFooterComponent } from './shared/components/page-footer/page-footer.component';
import { HomeComponent } from './public/home/home.component';
import { CompanyInformationComponent } from './public/company-information/company-information.component';
import { StartComponent } from './public/wizard/start/start.component';
import { AdditionalCompaniesComponent } from './public/company-information/additional-companies/index';
import { WizardQuestionComponent } from './public/wizard/question/index';
import { WizardProgressComponent } from './shared/components/wizard-progress/index';
import { WizardService } from './shared/services/wizard.service';
import { QuestionType1Component } from './shared/components/questions/type-1/index';
import { QuestionType2Component } from './shared/components/questions/type-2/index';
import { QuestionType3Component } from './shared/components/questions/type-3/index';
import { DecimalFormatterDirective } from './shared/components/decimal-formatter/decimal-formatter';
import { QuestionType4Component } from './shared/components/questions/type-4/index';
import { WizardResultComponent } from './public/wizard/result/index';
import { TransitionComponent } from './public/wizard/transition/index';
import { RechtsbijstandComponent } from './public/rechtsbijstand/index';
import { LegalProblemComponent } from './public/legal-problem/index';
import { BetterProposalModalComponent } from './public/company-information/better-proposal-modal/index';
import { QuestionType5Component } from './shared/components/questions/type-5/index';
import { AutofocusDirective } from './shared/components/input-autofocus/input-autofocus';
import { IntercomModule } from 'ng-intercom';
import { QuestionType5FormAddressComponent } from './shared/components/questions/type-5/type-5-form-address/index';
import { AuthService } from './auth';
import { LogoutComponent } from './public/logout';
import { NoContentComponent } from './public/no-content';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AlertService } from './shared/services';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PaginationComponent } from './shared/components/pagination';
import { SafePipe } from './shared/pipes/safe.pipe';
import { RedirectComponent } from './public/redirect/redirect.component';
import { GooglePlacesComponent } from './shared/components/google-places/google-places.component';
import { OktaComponent } from './public/okta/okta.component';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
const APP_PROVIDERS = [
    ...APP_RESOLVER_PROVIDERS,
    AppService,
    AuthService
];
export function createTranslateLoader(http: HttpClient) {
    return new TranslatePoHttpLoader(http, 'assets/i18n', '.po');
}

@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent,
        RechtsbijstandComponent,
        NgbdAlertComponent,
        HomeComponent,
        LegalProblemComponent,
        LogoutComponent,
        PaginationComponent,
        SafePipe,
        BetterProposalModalComponent,
        ConfirmDialogComponent,
        NoContentComponent,
        PageHeaderComponent,
        PageFooterComponent,
        StartComponent,
        WizardProgressComponent,
        CompanyInformationComponent,
        DecimalFormatterDirective,
        AutofocusDirective,
        AdditionalCompaniesComponent,
        WizardQuestionComponent,
        TransitionComponent,
        WizardResultComponent,
        QuestionType1Component,
        QuestionType2Component,
        QuestionType3Component,
        QuestionType4Component,
        QuestionType5Component,
        QuestionType5FormAddressComponent,
        RedirectComponent,
        OktaComponent,
        GooglePlacesComponent
    ],
    entryComponents: [ConfirmDialogComponent, BetterProposalModalComponent],
    imports: [
        BrowserModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule, // the change from http module
        RouterModule.forRoot(ROUTES, { useHash: true, preloadingStrategy: PreloadAllModules }),
        NgxClickToEditModule.forRoot(),
        BrowserAnimationsModule,
        SharedModule,
        NgbModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        IntercomModule.forRoot({
            appId: 'mer964ge', // from your Intercom config
            updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
        })
    ],
    providers: [ // expose our Services and Providers into Angular's dependency injection
        APP_PROVIDERS,
        AlertService,
        WizardService,
        CustomTranslateService,
        ConfirmDialogService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class AppModule {

    constructor(
        public appRef: ApplicationRef,
        public appService: AppService
    ) { }

}
