import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs';
import { NgForm } from "@angular/forms";
import { AlertService, CustomTranslateService } from "./index";
import { AlertTypes } from "../models/alert-types";

@Injectable()
export class FormValidationService {

    constructor(private alertService: AlertService, private translate: CustomTranslateService
    ) { }
    public validateForm(form: NgForm): Observable<Boolean> {
        return Observable.create((observer) => {

            if (form.valid) {
                if (form.dirty) {
                    observer.next(true);
                }
            } else {
                Object.keys(form.form.controls).map((key) => {
                    form.form.get(key).markAsDirty();
                    form.form.get(key).updateValueAndValidity();
                });

                observer.next(false);
            }
            observer.complete();
        });
    }
}
