import { Routes } from '@angular/router';
import { UserRoles } from './shared/models';
import { DataResolver } from './app.resolver';
import { AuthGuard } from './auth/auth-guard.service';
import { LoginComponent } from './public/login/index';
import { LogoutComponent } from './public/logout/index';
import { NoContentComponent } from './public/no-content/index';
import { HomeComponent } from './public/home/index';
import { CompanyInformationComponent } from './public/company-information/company-information.component';
import { StartComponent } from './public/wizard/start/index';
import { AdditionalCompaniesComponent } from './public/company-information/additional-companies/index';
import { WizardQuestionComponent } from './public/wizard/question/index';
import { WizardResultComponent } from './public/wizard/result/index';
import { TransitionComponent } from './public/wizard/transition/index';
import { RechtsbijstandComponent } from './public/rechtsbijstand/index';
import { LegalProblemComponent } from './public/legal-problem/index';
import { RedirectComponent } from './public/redirect/redirect.component';
import { OktaComponent } from './public/okta/okta.component';
// import { TestComponent } from './public/test/index';

export const ROUTES: Routes = [
  // { path: 'test', component: TestComponent },
  { path: 'risk-calculator', component: HomeComponent },
  { path: 'legal-problem', component: LegalProblemComponent },
  { path: 'legal-assistance', component: RechtsbijstandComponent },
  { path: 'company-information', component: CompanyInformationComponent },
  { path: '', component: HomeComponent }, // MBR2803-1104 : avant = LegalProblemComponent // MBR : avant = RechtsbijstandComponent
  { path: 'company-information/additional', component: AdditionalCompaniesComponent },
  { path: 'company-information/additional-companies', component: AdditionalCompaniesComponent },
  { path: 'wizard/start', component: StartComponent },

  { path: 'wizard/start/:uuid', component: StartComponent },
  { path: 'wizard/question/:category/:question', component: WizardQuestionComponent },
  { path: 'wizard/question/:category/complete', component: WizardQuestionComponent },
  { path: 'wizard/transition/:from/:to', component: TransitionComponent },
  { path: 'wizard/result', component: WizardResultComponent },
  { path: 'redirect', component: RedirectComponent},
  { path: '**', component: NoContentComponent },
  
];

export const ROUTEPERMISSIONS = [

];
