import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material";

@Component({
    selector: 'confirm-dialog',
    templateUrl: 'confirm-dialog.component.html'
})
export class ConfirmDialogComponent {
    public title: string;
    public text: string;
    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: any
    ) {
        this.text = dialogData.text;
        this.title = dialogData.title;
    }
}
