import { Input, Output, EventEmitter, Component, TemplateRef, ContentChild, ContentChildren, ElementRef, NgZone, QueryList, Renderer, Injectable, Injector, AfterContentChecked } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
// import { SourceVenue } from './../../../../../../../data/venues/sources/venue.model';
import { Http, RequestOptions, Response, Headers, URLSearchParams, ResponseContentType } from "@angular/http";
import 'rxjs/add/operator/toPromise';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { Subject } from "rxjs/Subject";
import { AuthService } from '../../../auth/auth.service';
import { AppService } from '../../../app.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'page-header',
  styleUrls: ['./page-header.component.scss'],
  templateUrl: './page-header.component.html'
})
@Injectable()
export class PageHeaderComponent {

  @Output()
  private entryClick = new EventEmitter();

  constructor(

    private renderer: Renderer,
    private ngZone: NgZone,
    private route: ActivatedRoute,
    private router: Router,
    private injector: Injector,
    private http: HttpClient,
    public appService: AppService,
    public authService: AuthService,
    private elementRef: ElementRef
  ) {
  }
}         
