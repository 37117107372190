import { Component } from '@angular/core';
import { AppService } from '../../../app.service';
import { WizardService } from '../../services/wizard.service';

@Component({
  selector: 'wizard-progress',
  styleUrls: ['./wizard-progress.component.scss'],
  templateUrl: './wizard-progress.component.html'
})
export class WizardProgressComponent {
  constructor(
    public appService: AppService,
    public wizardService: WizardService
  ) { }
}
