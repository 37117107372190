export class PageResult<T> {
    public nr: number;
    public total: number;
    public totalItems: number;
    public items: T[];

    public deserialize(json: any): PageResult<T> {
        if (json) {
            this.nr             = json.nr;
            this.total          = json.total;
            this.totalItems     = json.totalItems;
            this.items          = json.items;
        }
        return this;
    }
}
